import {Inject, Injectable} from '@angular/core';
import {IConfig} from 'src/app/interfaces/general/config.interface';

@Injectable({
    providedIn: 'root',
})
export class ConfigurationService {
    private readonly config: IConfig;

    constructor(@Inject('appConfigToken') appConfig: IConfig) {
        this.config = appConfig;
    }

    public get(key?: string | Array<string>): any {
        if (!key || (Array.isArray(key) && !key[0])) {
            return this.config;
        }

        if (!Array.isArray(key)) {
            key = key.split('.');
        }

        return key.reduce((acc: any, current: string) => {
            if (acc === undefined || acc[current] === undefined) {
                console.warn(`ConfigurationService: key "${current}" in the path "${(key as string[]).join('.')}" not found`);
            }
            return acc && acc[current];
        }, this.config);
    }

    public getCurrentEnv() {
        return this.get('environment');
    }
}
