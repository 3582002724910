import {ErrorHandler, Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {AngularPlugin} from '@microsoft/applicationinsights-angularplugin-js';
import {ApplicationInsights, ICustomProperties, ITraceTelemetry} from '@microsoft/applicationinsights-web';
import {ConfigurationService} from 'src/app/core/config/configuration.service';

@Injectable()
export class AppInsightsService {
    get insights() {
        return this.config.get('insights');
    }
    private angularPlugin = new AngularPlugin();
    private appInsights = new ApplicationInsights({
        config: {
            connectionString: this.insights.connectionString,
            disableTelemetry: this.insights.disableTelemetry,
            extensions: [this.angularPlugin],
            extensionConfig: {
                [this.angularPlugin.identifier]: {
                    router: this.router,
                    errorServices: [new ErrorHandler()],
                },
            },
            enableCorsCorrelation: true,
            enableRequestHeaderTracking: true,
            enableResponseHeaderTracking: true,
            // The Angular Plugin automatically tracks route changes and collects other Angular specific telemetry.
            // enableAutoRouteTracking should be set to false if it set to true then when the route changes duplicate PageViews may be sent.
            enableAutoRouteTracking: false,
        },
    });

    constructor(private router: Router, private config: ConfigurationService) {
        this.appInsights.loadAppInsights();
        this.appInsights.trackPageView();

        // Add cloud role
        this.appInsights.addTelemetryInitializer((envelope) => {
            const itemTags = envelope.tags ?? [];
            itemTags['ai.cloud.role'] = this.config.get('appRoleName');
        });
    }

    // expose methods that can be used in components and services
    public trackEvent(name: string): void {
        this.appInsights.trackEvent({name});
    }

    public trackTrace(message: ITraceTelemetry, properties?: ICustomProperties) {
        this.appInsights.trackTrace(message, properties);
    }
}